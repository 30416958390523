import {
  Falsy,
  useCall,
  useContractFunction,
  TransactionStatus,
} from '@usedapp/core';
import { utils, BigNumber } from 'ethers';
import { Contract } from '@ethersproject/contracts';
import vTestABI from '../../contracts/vTest/abi';

const nftInterface = new utils.Interface(vTestABI);
const nftAddress = process.env
  .REACT_APP_NFT_ADDRESS as string;

export const contract = new Contract(
  nftAddress,
  nftInterface,
);
export const contractAddress =
  process.env.REACT_APP_NFT_ADDRESS;

export interface verificationResult {
  valid: boolean;
  amount?: number;
  proof?: string;
}

export interface freeMintHookResult {
  sendFreeMint: (...args: any[]) => void;
  stateFreeMint: TransactionStatus;
}

export interface paidMintHookResult {
  sendPaidMint: (...args: any[]) => void;
  statePaidMint: TransactionStatus;
}

// returns the non-revealed URI
export const useNonRevealedURI = ():
  | BigNumber
  | undefined => {
  const { value, error } =
    useCall({
      contract,
      method: 'nonRevealedURI',
      args: [],
    }) ?? {};
  if (value) return value;
  if (error) return undefined;

  return undefined;
};

// returns the amount of NFTs owned by the address
export const useOwnedByAddress = (
  address: string | Falsy,
): Array<number> | undefined => {
  const { value, error } =
    useCall({
      contract,
      method: 'ownedByAddress',
      args: [address],
    }) ?? {};
  if (value) return value[0];
  if (error) return undefined;

  return undefined;
};

// checks if the merkle was already used by the user
export const useMerkleClaimed = (
  address: string | Falsy,
): boolean | undefined => {
  const { value, error } =
    useCall({
      contract,
      method: 'claimed',
      args: [address],
    }) ?? {};
  if (value) return value;
  if (error) return undefined;

  return undefined;
};

// todo: fix types
// mint free NFT based on merkle root
export const useFreeMint = (): freeMintHookResult => {
  const { state, send } = useContractFunction(
    contract,
    'freeMint',
    { transactionName: 'freeMint' },
  );
  const stateFreeMint = state;
  const sendFreeMint = send;
  return { stateFreeMint, sendFreeMint };
};

// mint paid NFT, user pays ethereum
export const usePaidMint = (): paidMintHookResult => {
  const { state, send } = useContractFunction(
    contract,
    'paidMint',
    { transactionName: 'paidMint' },
  );
  return { statePaidMint: state, sendPaidMint: send };
};
