import React, {
  FunctionComponent,
  useEffect,
  useState,
} from 'react';
import {
  useEthers,
  useBlockMeta,
  TransactionStatus,
} from '@usedapp/core';
import { utils } from 'ethers';
import { StandardMerkleTree } from '@openzeppelin/merkle-tree';
import WalletConnectButton from '../components/Buttons/WalletConnectButton';
import MetamaskConnectButton from '../components/Buttons/MetamaskConnectButton';
import {
  verificationResult,
  useFreeMint,
  usePaidMint,
  useOwnedByAddress,
  contractAddress,
  useMerkleClaimed,
} from '../helpers/contract/interface';
import TreeJson from '../merkle/tree.json';

const Page: FunctionComponent = () => {
  const { deactivate, account } = useEthers();

  const { blockNumber } = useBlockMeta();
  const [accountProof, setAccountProof] =
    useState<verificationResult>();
  const [mintAmount, setMintAmount] = useState(1);
  const costPerItemInETH =
    process.env.REACT_APP_NFT_COST_PER_ITEM_IN_ETH;

  const merkleClaimed = useMerkleClaimed(account);
  const nftsOwned = useOwnedByAddress(account);

  // load contract methods
  const { stateFreeMint, sendFreeMint } = useFreeMint();
  const { statePaidMint, sendPaidMint } = usePaidMint();

  // handlers
  const handleFreeMintClick = () => {
    sendFreeMint(accountProof?.proof, accountProof?.amount);
  };

  const handlePaidMintClick = () => {
    if (!costPerItemInETH) return;
    const etherAmount =
      parseFloat(costPerItemInETH) * mintAmount;
    sendPaidMint(mintAmount, {
      value: utils.parseEther(etherAmount.toString()),
    });
  };

  const handlePaidMintAmountChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ): void => {
    setMintAmount(parseInt(event.target.value, 10));
  };

  // handle notifications, this will move to a seperate toast component
  const handleNotifications = (
    statusObject: TransactionStatus,
  ): void => {
    if (statusObject.status === 'None') return;
    if (statusObject.status === 'PendingSignature') {
      // show pending signature toast
    }
    if (statusObject.status === 'Mining') {
      // show pending tx toast
    }
    if (statusObject.status === 'Success') {
      // remove toast
    }
    if (
      statusObject.status === 'Fail' ||
      statusObject.status === 'Exception'
    ) {
      // remove toast
    }
  };

  useEffect(() => {
    handleNotifications(statePaidMint);
  }, [statePaidMint]);

  useEffect(() => {
    handleNotifications(stateFreeMint);
  }, [stateFreeMint]);

  useEffect(() => {
    const verifyTree = (
      address: string,
    ): verificationResult => {
      const treeImport: any = StandardMerkleTree.load(
        JSON.parse(JSON.stringify(TreeJson).toString()),
      );

      // @TODO FIX THIS!
      // eslint-disable-next-line no-restricted-syntax
      for (const [i, v] of treeImport.entries()) {
        if (v[0] === address) {
          const proof = treeImport.getProof(i);
          const amount = v[1];
          return { valid: true, proof, amount };
        }
      }
      return { valid: false };
    };

    if (account) {
      const verification = verifyTree(account);
      if (verification.proof && verification.amount)
        setAccountProof(verification);
    } else {
      setAccountProof({
        valid: false,
        proof: undefined,
        amount: 0,
      });
    }
  }, [account]);

  return (
    // CODE BELOW IS A FUNCTIONAL PLACEHOLDER TO TEST SMART CONTRACT INTERACTIONS
    <>
      <h2>Mint Goerli Test</h2>
      <p>Current Block Number: {blockNumber}</p>
      <p>
        Mint Test Address Deployed at{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href={`https://goerli.etherscan.io/address/${contractAddress}`}
        >
          {process.env.REACT_APP_NFT_ADDRESS}
        </a>
      </p>

      <h3>Account Status</h3>

      <p>
        <strong>Merkle Initial Status?</strong>
      </p>
      {accountProof?.valid === true && (
        <>
          <p>
            Proof: {accountProof?.proof}, amount{' '}
            {accountProof?.amount}{' '}
          </p>
          <p>
            Merkle was claimed?{' '}
            {merkleClaimed?.toString() || 'false'}{' '}
          </p>
          <p>
            <strong>Available Minting Options: </strong>
          </p>
          {merkleClaimed?.toString() === 'false' && (
            <p>
              <button
                type="button"
                onClick={handleFreeMintClick}
              >
                Free Mint
              </button>
            </p>
          )}
          <p>
            <button
              type="button"
              onClick={handlePaidMintClick}
            >
              Paid Mint
            </button>
            <select onChange={handlePaidMintAmountChange}>
              <option>1</option>
              <option>2</option>
              <option>3</option>
            </select>
          </p>
        </>
      )}
      {accountProof?.valid === false && (
        <p>No Merkle Available </p>
      )}

      <p>
        <strong>
          Number of NFTs currently owned by this address
        </strong>{' '}
        {nftsOwned?.length.toString() || 0}
      </p>
      <p>
        <strong>IDS of the owned NFTs</strong>{' '}
        {nftsOwned?.toString()}
      </p>

      {!account && (
        <>
          <MetamaskConnectButton />
          <WalletConnectButton />
        </>
      )}
      {account && <p>Account: {account}</p>}
      {account && (
        <button type="button" onClick={() => deactivate()}>
          Disconnect
        </button>
      )}
    </>
  );
};

export default Page;
