import React, { FunctionComponent } from 'react';
import { useEthers } from '@usedapp/core';
import WalletConnectProvider from '@walletconnect/web3-provider';
import { StyledButton } from './styled';

const WalletConnectButton: FunctionComponent = () => {
  const { activate } = useEthers();
  async function onConnect() {
    try {
      const provider = new WalletConnectProvider({
        infuraId: process.env.REACT_APP_INFURA_ID,
      });
      await provider.enable();
      await activate(provider);
    } catch (error) {
      console.error(error);
    }
  }
  return (
    <StyledButton onClick={() => onConnect()}>
      Connect Wallet Connect
    </StyledButton>
  );
};

export default WalletConnectButton;
