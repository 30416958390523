import styled from 'styled-components';

export const StyledButton = styled.a`
  display: inline-block;
  border-radius: 3px;
  padding: 0.5rem 0;
  margin-left: 0;
  margin-right: 1rem;
  width: 11rem;
  background: black;
  color: white;
  text-align: center;
  cursor: pointer;
  width: 250px;
  border: 2px solid white;
`;
