import React, { useState, useEffect } from 'react';
import { useRoutes } from 'react-router-dom';
import { useEthers } from '@usedapp/core';
import GlobalStyle from './globalStyle';
import routerList from './routes';

import NavBar from './components/NavBar';
import Footer from './components/Footer';

const App: React.FunctionComponent = () => {
  const routes = useRoutes(routerList);
  const { account, chainId } = useEthers();
  const [connectionWarning, setConnectionWarning] =
    useState('');

  useEffect(() => {
    const validateChainId = (): void => {
      if (
        account &&
        chainId !==
          parseInt(
            process.env.REACT_APP_CHAIN_ID as string,
            10,
          )
      ) {
        setConnectionWarning(
          `Connected to wrong chain, please connect to ${process.env.REACT_APP_CHAIN_NAME}`,
        );
      } else {
        setConnectionWarning('');
      }
    };

    validateChainId();
  }, [chainId, account]);

  return (
    <>
      <GlobalStyle />
      <NavBar />
      {connectionWarning.length > 0 && connectionWarning}
      {routes}
      <Footer />
    </>
  );
};

export default App;
