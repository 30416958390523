import React from 'react';
import Mint from './pages/mint';
import Gallery from './pages/gallery';

const routerList: { path: string; element: JSX.Element }[] =
  [
    {
      path: '/',
      element: <Mint />,
    },
    {
      path: '/gallery',
      element: <Gallery />,
    },
  ];

export default routerList;
