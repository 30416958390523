import React from 'react';
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import {
  Goerli,
  DAppProvider,
  Config,
} from '@usedapp/core';
import App from './App';

const config: Config = {
  readOnlyChainId: Goerli.chainId,
  readOnlyUrls: {
    [Goerli.chainId]: process.env
      .REACT_APP_ALCHEMY_GOERLI_HTTP_RPC as string,
  },
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <DAppProvider config={config}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </DAppProvider>,
);
