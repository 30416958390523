import React, { FunctionComponent } from 'react';
import { useEthers } from '@usedapp/core';
import { StyledButton } from './styled';

const MetamaskConnectButton: FunctionComponent = () => {
  const { activateBrowserWallet } = useEthers();
  return (
    <StyledButton onClick={() => activateBrowserWallet()}>
      Connect Metamask
    </StyledButton>
  );
};

export default MetamaskConnectButton;
